import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { useEnterpriseOfferEnabled } from "@/react/hooks/useEnterpriseOfferEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  getNextTier,
  isPlanBasic,
  planTiers,
} from "@circle-react/helpers/planHelpers";
import { CurrencySelector } from "@circle-react-shared/Billing/CurrencySelector";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { LineItem } from "./LineItem";
import { useUpgradePlanContext } from "./UpgradePlanContext";

const i18nRoot = "pricing_plans.upgrade_modal";

interface PlanDetailsProps {
  subheading?: string;
  hideModal: () => void;
  onSubmitHandler: () => void;
}

const getDisplaySubheading = (
  subheading: string,
  planTier: string | undefined,
  isEnterpriseOfferEnabled: boolean,
  isMarketingHubOnlyUpgrade: boolean,
): string => {
  if (planTier === planTiers.enterprise && isEnterpriseOfferEnabled) {
    return t([i18nRoot, "enterprise_price_increase.description"]);
  }

  if (isMarketingHubOnlyUpgrade) {
    return t("pricing_plans.upgrade_modal.marketing_hub_value_prop_title");
  }

  return subheading;
};

export const PlanDetails = ({
  subheading = "",
  hideModal,
  onSubmitHandler,
}: PlanDetailsProps) => {
  const {
    billingCurrency,
    setBillingCurrency,
    plan,
    marketingHubPlan,
    shouldShowCurrencySelection,
    isMarketingHubRequired,
    isMarketingHubOnlyUpgrade,
    isDoubleUpgrade,
  } = useUpgradePlanContext();

  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity || {};
  const { isEnterpriseOfferEnabled } = useEnterpriseOfferEnabled();
  // [TODO][EnterprisePriceIncrease][AfterRelease] Remove this and related logic
  const isEnterpriseOfferVisible =
    isEnterpriseOfferEnabled && plan?.tier === planTiers.enterprise;

  const learnMoreRedirect = (): void => {
    window.location.href = planSettingsPath();
  };

  const nextTier = getNextTier(currentPlanTier);

  let everythingInCopy: string;
  if (isMarketingHubOnlyUpgrade) {
    everythingInCopy = "marketing_hub_features_title";
  } else if (nextTier === plan?.tier) {
    everythingInCopy = `everything_in_${plan.tier}`;
  } else {
    everythingInCopy = "everything_in_your_plan_plus";
  }

  const shouldShowFeatureList = !isPlanBasic(plan?.tier);
  const featuresToDisplay =
    plan?.main_features || marketingHubPlan?.main_features || [];

  const isPlanOnOffer = plan?.plan_coupon?.formatted_discount_percentage;
  const displayIntervalText =
    plan?.plan_coupon?.interval_abbreviation ||
    plan?.interval_abbreviation ||
    "";

  const amountWithCurrency = isPlanOnOffer
    ? plan?.plan_coupon?.discounted_monthly_price_with_currency || ""
    : plan?.amount_without_currency || "";

  const displayName =
    plan?.display_name ||
    marketingHubPlan?.display_name ||
    t([i18nRoot, "default_plan_name"]);

  const onClickHandler = (): void => {
    onSubmitHandler();
  };

  const displaySubheading = getDisplaySubheading(
    subheading,
    plan?.tier,
    isEnterpriseOfferEnabled,
    isMarketingHubOnlyUpgrade,
  );

  // Create a properly typed discountPercentage variable
  const getDiscountPercentage = (): string | null => {
    if (plan?.plan_coupon?.formatted_discount_percentage) {
      return plan.plan_coupon.formatted_discount_percentage;
    }
    return null;
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t([i18nRoot, "title"], { plan: displayName })}
        </Modal.Title>

        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        {displaySubheading && (
          <div className="mb-6">
            <Typography.BodyMd color="text-default">
              {displaySubheading}
            </Typography.BodyMd>
          </div>
        )}

        {isEnterpriseOfferVisible && (
          <div className="rounded-t-lg bg-blue-600 px-4 py-2 text-center text-white">
            <Typography.BodySm color="text-white">
              {t(
                "pricing_plans.upgrade_modal.enterprise_price_increase.limited_time_offer",
              )}
            </Typography.BodySm>
          </div>
        )}
        <div
          className={`border-primary rounded-lg border p-5 ${isEnterpriseOfferVisible ? "rounded-t-none" : ""}`}
        >
          {plan && (
            <LineItem
              displayName={t([i18nRoot, "plan_name"], {
                plan: plan.display_name,
              })}
              discountPercentage={getDiscountPercentage()}
              amountWithCurrency={amountWithCurrency}
              displayIntervalText={displayIntervalText}
              description={t([
                i18nRoot,
                `${plan.tier || "default"}_description`,
              ])}
              isFirstLineItem={isMarketingHubRequired}
              featuresToDisplay={featuresToDisplay}
              isDoubleUpgrade={isDoubleUpgrade}
            />
          )}

          {isMarketingHubRequired && marketingHubPlan && (
            <LineItem
              displayName={t([i18nRoot, "marketing_hub_plan_name"], {
                plan: marketingHubPlan.display_name,
              })}
              amountWithCurrency={
                marketingHubPlan.amount_without_currency || ""
              }
              displayIntervalText={marketingHubPlan.interval_abbreviation || ""}
              description={t([i18nRoot, "marketing_hub_description"])}
              mhMaximumTierUsage={marketingHubPlan.current_tier_up_to}
              mhCurrentUsage={marketingHubPlan.contact_count}
              isDoubleUpgrade={isDoubleUpgrade}
              isFreeTier={marketingHubPlan.is_free_tier}
            />
          )}
        </div>

        {shouldShowCurrencySelection && (
          <CurrencySelector
            billingCurrency={billingCurrency || null}
            onCurrencyChange={setBillingCurrency}
            className="bg-tertiary mt-2"
            minimal
          />
        )}

        {!isDoubleUpgrade && shouldShowFeatureList && (
          <>
            <div className="mt-6">
              <Typography.LabelLg weight="semibold">
                {t([i18nRoot, everythingInCopy])}
              </Typography.LabelLg>
            </div>

            {featuresToDisplay.length > 0 && (
              <ul className="mt-6 flex list-none flex-col gap-5">
                {featuresToDisplay.map((feature: string, index: number) => {
                  const itemKey = `feature-item-${index}`;
                  return (
                    <li key={itemKey} className="flex items-center gap-3">
                      <Icon
                        type="20-checkmark-blue"
                        size={20}
                        className="!h-5 !w-5"
                      />
                      <Typography.LabelMd weight="medium">
                        {feature}
                      </Typography.LabelMd>
                    </li>
                  );
                })}
              </ul>
            )}

            {!isMarketingHubOnlyUpgrade && (
              <div className="mt-5">
                <Link to={planSettingsPath()} onClick={learnMoreRedirect}>
                  <Typography.LabelMd weight="medium" color="text-default">
                    {t([i18nRoot, "learn_more"])}
                  </Typography.LabelMd>
                </Link>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button variant="circle" large full onClick={onClickHandler}>
          {t([i18nRoot, "actions.upgrade"], { plan: displayName })}
        </Button>
      </Modal.Footer>
    </>
  );
};
