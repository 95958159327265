import { useEffect, useState } from "react";
import classNames from "classnames";
import { supportedCurrencies } from "@circle-react/config/Currencies";

interface CurrencySelectorProps {
  billingCurrency: string | null;
  onCurrencyChange?: (currency: string) => void;
  minimal?: boolean;
  className?: string;
  isRedesignedPlansPage?: boolean;
}

export const CurrencySelector = ({
  billingCurrency,
  onCurrencyChange = () => {},
  minimal = false,
  className,
  isRedesignedPlansPage = false,
}: CurrencySelectorProps) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  useEffect(() => {
    if (billingCurrency) {
      setSelectedCurrency(billingCurrency.toUpperCase());
    }
  }, [billingCurrency]);

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
    onCurrencyChange(currency);
  };

  if (!selectedCurrency) return null;

  const renderOptions = ({ useSymbol }: { useSymbol: boolean }) =>
    Object.entries(supportedCurrencies).map(
      ([currency, { code, flag, symbol }]) => (
        <option key={currency} value={code}>
          {`${useSymbol ? symbol : flag} ${code}`}
        </option>
      ),
    );

  if (isRedesignedPlansPage) {
    return (
      <div className="flex items-center">
        <div className="relative w-fit">
          <select
            className="appearance-none border-none bg-transparent text-xs text-slate-700 focus:outline-none dark:text-slate-300"
            onChange={e => handleCurrencyChange(e.target.value)}
            value={selectedCurrency}
          >
            {renderOptions({ useSymbol: true })}
          </select>
        </div>
      </div>
    );
  }

  const defaultSelect = (
    <select
      className={classNames(
        "border-primary block appearance-none rounded-md border bg-white py-2 pl-3 pr-8 text-base leading-6",
        "transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none",
        className,
      )}
      onChange={e => handleCurrencyChange(e.target.value)}
      value={selectedCurrency}
    >
      {renderOptions({ useSymbol: false })}
    </select>
  );

  return minimal ? (
    <div className="flex items-center">{defaultSelect}</div>
  ) : (
    <div className="mt-3">
      <div className="mb-8 mt-2 md:mb-0 md:mr-2 md:pr-2">
        <div className="relative block text-left">
          {defaultSelect}
          <div className="border-primary pointer-events-none absolute inset-y-0 right-0 flex items-center px-2" />
        </div>
      </div>
    </div>
  );
};
