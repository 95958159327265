import { MemoryRouter } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ContactSupport } from "./ContactSupport";
import { Loader } from "./Loader";
import { UpgradeFlow } from "./UpgradeFlow";
import type { UpgradePlanProviderProps } from "./UpgradePlanContext";
import {
  UpgradePlanProvider,
  useUpgradePlanContext,
} from "./UpgradePlanContext";

const i18nRoot = "pricing_plans.upgrade_modal";

export interface UpgradePlanModalComponentProps {
  subheading?: string;
  onSuccess?: () => void | Promise<void>;
  successButtonLabel?: string;
  onCloseModal?: () => void | Promise<void>;
}

// we need to support 2 different flows for trials
// 1. from workflows we show the plan details (showPlanDetails = true)
// 2. from plans page we redirect to stripe checkout session immediately
// (showPlanDetails = false)

// Todo: Missing translations for Pro and Basic when showPlanDetails = true
const UpgradePlanModalComponent = ({
  subheading,
  onSuccess,
  successButtonLabel,
  onCloseModal,
}: UpgradePlanModalComponentProps) => {
  const {
    isPlanLoading,
    isMarketingHubPlanLoading,
    errorFetchingMarketingHubPlan,
    errorFetchingPlan,
    isMarketingHubRequired,
  } = useUpgradePlanContext();

  const modal = useModal();

  const handleOnSuccess = async () => {
    void modal.hide();
    modal.remove();
    if (isMarketingHubRequired) {
      // [TODO][MarketingHubBilling][AfterRelease] Remove this an diagnose the actual issue
      window.location.reload();
      return;
    }
    if (onSuccess) {
      await onSuccess();
    }
  };

  const handleOnClose = async () => {
    if (onCloseModal) {
      await onCloseModal();
    }
    await modal.hide();
    modal.remove();
  };

  return (
    <Modal isOpen={modal.visible} onClose={handleOnClose}>
      <Modal.Overlay />
      <Modal.Content size="md" className="!max-h-[85vh] !w-[35.25rem]">
        {isPlanLoading || isMarketingHubPlanLoading ? (
          <Loader title={t([i18nRoot, "loading.plan"])} />
        ) : errorFetchingPlan || errorFetchingMarketingHubPlan ? (
          <ContactSupport
            title={t([i18nRoot, "errors.plan_not_found.title"])}
            errorMessage={t([i18nRoot, "errors.plan_not_found.message"])}
          />
        ) : (
          <UpgradeFlow
            subheading={subheading}
            successButtonLabel={successButtonLabel}
            handleOnSuccess={handleOnSuccess}
            modal={modal}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export interface UpgradePlanModalProps
  extends Omit<UpgradePlanProviderProps, "children">,
    UpgradePlanModalComponentProps {}

const UpgradePlanModal = ModalManager.create(
  ({
    planTier,
    subheading,
    source,
    onSuccess,
    successButtonLabel,
    usedForWorkflows,
    isMarketingHubRequired,
    showPlanDetails = false,
    couponId,
    planId,
    onCloseModal,
  }: UpgradePlanModalProps) => (
    <MemoryRouter>
      <UpgradePlanProvider
        planTier={planTier}
        planId={planId}
        couponId={couponId}
        source={source}
        usedForWorkflows={usedForWorkflows}
        isMarketingHubRequired={isMarketingHubRequired}
        showPlanDetails={showPlanDetails}
      >
        <UpgradePlanModalComponent
          subheading={subheading}
          onSuccess={onSuccess}
          successButtonLabel={successButtonLabel}
          onCloseModal={onCloseModal}
        />
      </UpgradePlanProvider>
    </MemoryRouter>
  ),
);

export const useUpgradePlanModal = () => useModal(UpgradePlanModal);
