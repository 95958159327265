import { useCallback } from "react";
import type { EventType } from "@/react/hooks/analytics/types";
import { useEventsTracker } from "@circle-react/hooks/analytics/useEventsTracker";
import { useUpgradePlanContext } from "./UpgradePlanContext";
import { UPGRADE_PATHS } from "./constants";

const PATH_TO_EVENT_MAP: Record<string, EventType> = {
  [UPGRADE_PATHS.INITIAL]: "user.plan_change.initiated",
  [UPGRADE_PATHS.PLAN_DETAILS]: "user.plan_change.plan_details",
  [UPGRADE_PATHS.WORKFLOW_APP_SYNCING]: "user.plan_change.workflow_app_syncing",
  [UPGRADE_PATHS.TAX_ID_FORM]: "user.plan_change.tax_id_form",
  [UPGRADE_PATHS.CAN_DOWNGRADE]: "user.plan_change.can_downgrade",
  [UPGRADE_PATHS.MARKETING_HUB_SUBSCRIPTION_CHECK]:
    "user.plan_change.marketing_hub_subscription_check",
  [UPGRADE_PATHS.UPGRADE_SUCCESSFUL]: "user.plan_change.completed",
  [UPGRADE_PATHS.BLOCKED_UPGRADE]: "user.plan_change.blocked",
  [UPGRADE_PATHS.PRORATION]: "user.plan_change.proration",
  [UPGRADE_PATHS.ERROR]: "user.plan_change.error",
  [UPGRADE_PATHS.LOADING]: "user.plan_change.loading",
};

export const useUpgradeFlowAnalytics = () => {
  const { plan, source, couponId, marketingHubPlan } = useUpgradePlanContext();

  const { trackEvent } = useEventsTracker();

  const trackUpgradeFlowPathChange = useCallback(
    (path: string) => {
      const specificProperties = {
        plan: plan
          ? {
              id: plan.id,
              tier: plan.tier,
              interval: plan.interval,
              currency: plan.currency,
              amount_with_currency: plan.amount_with_currency,
            }
          : undefined,
        marketing_hub: !!marketingHubPlan,
        coupon_id: couponId,
        source,
      };

      const eventName = PATH_TO_EVENT_MAP[path];

      if (eventName) {
        trackEvent(eventName, specificProperties);
      }
    },
    [plan, source, couponId, trackEvent],
  );

  return {
    trackUpgradeFlowPathChange,
  };
};
