import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCurrentPlanApi } from "@circle-react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { UPGRADE_PATHS } from "@circle-react-shared/UpgradePlan/constants";
import { Loader } from "./Loader";

const i18nRoot = "pricing_plans.upgrade_modal";
export const MarketingHubSubscriptionCheck = () => {
  const { currentPlan, refetchCurrentPlan } = useCurrentPlanApi();
  const history = useHistory();

  const isMarketingHubEnabled =
    currentPlan?.marketing_hub_billing?.marketing_hub_billing_active;

  useEffect(() => {
    const intervalId = setInterval(refetchCurrentPlan, 1000);
    return () => clearInterval(intervalId);
  }, [refetchCurrentPlan]);

  useEffect(() => {
    if (isMarketingHubEnabled) {
      history.push(UPGRADE_PATHS.UPGRADE_SUCCESSFUL);
    }
  }, [isMarketingHubEnabled, history]);

  return (
    <Loader
      title={t([i18nRoot, "loading.creating_marketing_hub_subscription"])}
      subtitle={t("pricing_plans.upgrade_modal.updating_plan_description")}
    />
  );
};
