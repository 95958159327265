import { noop, uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import { ImageUploadField } from "@circle-react/components/CommunityMember/EditCurrentProfile/Form/ImageUpload";
import { useMemberProfileModal } from "@circle-react/components/Modals/MemberProfileModal/hooks/useMemberProfileModal";
import { EmptyAvatar } from "@circle-react-shared/Placeholders";
import { ProfileFormFooter } from "@circle-react-shared/ProfileModal";
import { Form } from "@circle-react-uikit/Form";
import { CustomFieldsList, Permissions } from "../FormFields";
import type { Field } from "../FormFields/CustomFieldsList";
import useStaticFieldConfig from "../StaticContent/useStaticFieldConfig";
import { getValidationSchema } from "../formUtils";

const formId = uniqueId();

const defaultFormValue = {
  preferences: {
    visible_in_member_directory: true,
  },
} as const;

export interface ProfileFormProps {
  fields?: {
    visibleFields?: Field[];
  };
  onSubmit?: (values: any) => void;
  isPreview?: boolean;
  defaultValues?: any;
}

export const ProfileForm = ({
  fields = {},
  onSubmit = noop,
  isPreview = true,
  defaultValues = defaultFormValue,
}: ProfileFormProps) => {
  const { visibleFields = [] } = fields;
  const { handleClose } = useMemberProfileModal();

  const { STATIC_FORM_COMPONENTS } = useStaticFieldConfig();

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(visibleFields)}
      id={formId}
      noValidate
    >
      <div className="pb-4">
        {isPreview ? (
          <EmptyAvatar size="lg" />
        ) : (
          <ImageUploadField title={t("upload_a_profile_photo")} />
        )}
      </div>
      {STATIC_FORM_COMPONENTS.map(({ id, component: Component }) => (
        <Component key={id} />
      ))}
      <CustomFieldsList fields={visibleFields} />
      <Permissions />
      {!isPreview && (
        <ProfileFormFooter formId={formId} onClose={handleClose} />
      )}
    </Form>
  );
};
