import { t } from "@/i18n-js/instance";
import { planActions } from "@/react/helpers/plansHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ContactSupport } from "../ContactSupport";
import { Loader } from "../Loader";
import { useUpgradePlanContext } from "../UpgradePlanContext";
import { useFetchProration } from "../hooks/useFetchProration";
import { Details } from "./Details";
import { TotalDue } from "./TotalDue";

const i18nRoot = "pricing_plans.upgrade_modal";

export const Proration = ({ hideModal, onSubmitHandler }: any) => {
  const { plan, couponId: planCouponId } = useUpgradePlanContext();
  const { prorationData, isProrationLoading, errorFetchingProration } =
    useFetchProration({
      new_plan_id: plan.id,
      plan_coupon_id: planCouponId,
    });

  if (isProrationLoading) {
    return <Loader title={t([i18nRoot, "loading.proration"])} />;
  }

  if (errorFetchingProration) {
    return (
      <ContactSupport
        title={t([i18nRoot, "errors.calculating_proration.title"])}
        errorMessage={t([i18nRoot, "errors.calculating_proration.message"])}
      />
    );
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t("pricing_plans.change_plan.title")}</Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        <Details prorationData={prorationData} />
        <TotalDue totalDue={prorationData?.display_total_due_today} />
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button variant="circle" large full onClick={onSubmitHandler}>
          {t(`pricing_plans.actions.${planActions.change_plan}`)}
        </Button>
      </Modal.Footer>
    </>
  );
};
