import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useSupportWidgetContext } from "@circle-react/components/SupportWidget/SupportWidgetContext";
import { ErrorDetails } from "./ErrorDetails";

export const ContactSupport = ({
  title,
  errorMessage,
  iconColor = "red",
  supportType = "email",
  hideModal,
}) => {
  const { toggleWidget } = useSupportWidgetContext();

  const handleEmailSupport = () => {
    window.open("mailto:support@circle.so", "emailWindow");
  };

  const handleSupport = () => {
    if (supportType === "chat") {
      hideModal();
      toggleWidget();
    } else {
      handleEmailSupport();
    }
  };

  return (
    <ErrorDetails
      title={title}
      errorMessage={errorMessage}
      buttonLabel={t("pricing_plans.upgrade_modal.actions.contact_support")}
      onClick={handleSupport}
      iconColor={iconColor}
    />
  );
};

ContactSupport.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  iconColor: PropTypes.string,
  supportType: PropTypes.oneOf(["email", "chat"]),
  hideModal: PropTypes.func,
};
