import { useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isDowngrading } from "@/react/helpers/planHelpers";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { useUpgradePlanContext } from "../UpgradePlanContext";
import { UPGRADE_PATHS } from "../constants";
import { useAccountPaymentProcessorsApi } from "./useAccountPaymentProcessorsApi";
import { useAccountSubscriptionApi } from "./useAccountSubscriptionApi";

const i18nRoot = "pricing_plans.upgrade_modal";

export const useUpgradePlanForm = ({
  usedForWorkflows,
  showProration,
  canCollectTaxId = false,
  source,
}) => {
  const { couponId, plan } = useUpgradePlanContext();
  const { createCheckoutSession } = useAccountPaymentProcessorsApi();
  const history = useHistory();
  const [loadingMessage, setloadingMessage] = useState({
    title: "",
    subtitle: "",
  });
  const {
    isCommunityOnTrial,
    currentPlanTier,
    isExpiredTrial,
    isCommunitySubscriptionIncompleteExpired,
  } = usePlanStatus();
  const { updateSubscription, createMarketingHubSubscription } =
    useAccountSubscriptionApi({
      usedForWorkflows,
      source,
    });

  const marketingHubOnlySubmitHandler = () => {
    // [TODO][MarketingHubBilling][AfterRelease] Add proration step
    setloadingMessage({
      title: t(
        "pricing_plans.upgrade_modal.loading.preparing_hub_subscription",
      ),
      subtitle: t("pricing_plans.upgrade_modal.updating_plan_description"),
    });
    history.push(UPGRADE_PATHS.LOADING);
    createMarketingHubSubscription();
  };

  const onSubmitHandler = (taxIdData = null) => {
    const currentPage = history.location.pathname;

    const isAtPlanDetails = currentPage === UPGRADE_PATHS.PLAN_DETAILS;
    const isAtTaxId = currentPage === UPGRADE_PATHS.TAX_ID;

    if (isAtPlanDetails && canCollectTaxId) {
      history.push(UPGRADE_PATHS.TAX_ID_FORM);
    } else if ((isAtTaxId || isAtPlanDetails) && showProration) {
      history.push(UPGRADE_PATHS.PRORATION);
    } else {
      handleDowngrade(plan, couponId, taxIdData);
    }
  };

  const handleDowngrade = (plan, couponId, taxIdData) => {
    if (isCommunityOnTrial && !isExpiredTrial) {
      changePlan(plan, couponId, taxIdData);
      return;
    }

    const isDowngradingThePlan = isDowngrading(currentPlanTier, plan.tier);
    if (isDowngradingThePlan) {
      history.push(UPGRADE_PATHS.CAN_DOWNGRADE);
    } else {
      changePlan(plan, couponId, taxIdData);
    }
  };

  const changePlan = (plan, couponId, taxIdData) => {
    if (
      isCommunityOnTrial ||
      isExpiredTrial ||
      isCommunitySubscriptionIncompleteExpired
    ) {
      setloadingMessage({
        subtitle: "",
        title: t([i18nRoot, "stripe_checkout_title"]),
      });
      createCheckoutSession({
        plan_id: plan.id,
        coupon_id: couponId,
        source,
        tax_id_data: taxIdData,
      });
    } else {
      setloadingMessage({
        subtitle: t([i18nRoot, "updating_plan_description"]),
        title: t([i18nRoot, "updating_plan"]),
      });
      updateSubscription({ planId: plan.id, planCouponId: couponId });
    }
    history.push(UPGRADE_PATHS.LOADING);
  };

  return {
    onSubmitHandler,
    changePlan,
    loadingMessage,
    marketingHubOnlySubmitHandler,
  };
};
