import type { MouseEventHandler } from "react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { SelectedText } from "./types";

interface AddonFilterChipProps {
  selectedText?: SelectedText;
  onClear: MouseEventHandler<HTMLButtonElement>;
  shouldShowClearButton?: boolean;
}

export const AddonFilterChip = ({
  selectedText,
  onClear,
  shouldShowClearButton = true,
}: AddonFilterChipProps) => {
  if (!selectedText) {
    return (
      <Icon type="16-add-sm" size={16} aria-hidden useWithFillCurrentColor />
    );
  }
  if (!shouldShowClearButton) {
    // Maintain spacing on the Filterbar
    return <div aria-hidden />;
  }
  return (
    <button
      type="button"
      className="focus-visible:ring-secondary focus-visible:ring-1"
      aria-label={t("filters.clear")}
      onClick={onClear}
    >
      <Icon type="16-close" size={16} useWithStrokeCurrentColor />
    </button>
  );
};
