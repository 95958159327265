import { t } from "@/i18n-js/instance";
import { ContactSupport } from "./ContactSupport";

export const BlockedUpgrade = ({ hideModal }: { hideModal: () => void }) => (
  <ContactSupport
    title={t(
      "pricing_plans.upgrade_modal.errors.block_interval_change_marketing_hub.title",
    )}
    errorMessage={t(
      "pricing_plans.upgrade_modal.errors.block_interval_change_marketing_hub.message",
    )}
    iconColor="grey"
    supportType="chat"
    hideModal={hideModal}
  />
);
