import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "pricing_plans.upgrade_modal";

export interface UpgradeSuccessfulProps {
  buttonLabel?: string;
  onSuccess: () => void;
  unlockedFeatures?: string;
}

export const UpgradeSuccessful = ({
  buttonLabel,
  onSuccess,
  unlockedFeatures,
}: UpgradeSuccessfulProps) => (
  <>
    <Modal.Body>
      <div className="mt-16 text-center">
        <Icon type="spaceship-blue" size={56} className="mb-10 !w-14" />

        <Typography.TitleLg color="text-darkest">
          {t([i18nRoot, "update_successful"])}
        </Typography.TitleLg>

        {unlockedFeatures && (
          <div className="mt-3">
            <Typography.LabelMd color="text-default">
              {unlockedFeatures}
            </Typography.LabelMd>
          </div>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer className="mt-5">
      <Button variant="circle" large full onClick={onSuccess}>
        {buttonLabel}
      </Button>
    </Modal.Footer>
  </>
);
