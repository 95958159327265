import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { accountSubscriptionApi } from "@/react/api/accountSubscriptionApi";
import { useCurrentPlanApi } from "@circle-react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { useUpgradePlanContext } from "../UpgradePlanContext";
import { UPGRADE_PATHS } from "../constants";

interface UseAccountSubscriptionApiOptions {
  usedForWorkflows?: boolean;
  source?: string;
}

interface UpdateSubscriptionParams {
  planId: string;
  planCouponId?: string; // Make this optional if it's not required for every call
}

export const useAccountSubscriptionApi = ({
  usedForWorkflows = false,
  source,
}: UseAccountSubscriptionApiOptions = {}) => {
  const history = useHistory();
  const { isMarketingHubRequired } = useUpgradePlanContext();
  const { refetchCurrentPlan } = useCurrentPlanApi();

  const handleError = () => {
    history.push(UPGRADE_PATHS.ERROR);
  };

  // [TODO][MarketingHubBilling][AfterRelease] Rename "updateSubscription" to communityHubUpgrade
  // or something more descriptive as we now have two different subscriptions
  const { mutate: updateSubscription } = useMutation(
    ({ planId, planCouponId }: UpdateSubscriptionParams) =>
      accountSubscriptionApi.update(planId, planCouponId, source),
    {
      onSuccess: () => {
        void refetchCurrentPlan();

        if (isMarketingHubRequired) {
          // Its a CH and MH upgrade
          createMarketingHubSubscription();
        } else if (usedForWorkflows) {
          // Its a CH only upgrade for workflows
          history.push(UPGRADE_PATHS.WORKFLOW_APP_SYNCING);
        } else {
          // Its a CH only upgrade
          history.push(UPGRADE_PATHS.UPGRADE_SUCCESSFUL);
        }
      },
      onError: handleError,
    },
  );

  const { mutate: createMarketingHubSubscription } = useMutation(
    () => accountSubscriptionApi.createMarketingHubSubscription(),
    {
      onSuccess: () => {
        if (usedForWorkflows) {
          history.push(UPGRADE_PATHS.WORKFLOW_APP_SYNCING);
        } else {
          history.push(UPGRADE_PATHS.MARKETING_HUB_SUBSCRIPTION_CHECK);
        }
      },
      onError: handleError,
    },
  );

  return {
    updateSubscription,
    createMarketingHubSubscription,
  };
};
