import { t } from "@/i18n-js/instance";
import { useArchivedFieldsMutation } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useArchivedFieldsMutation";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

interface FieldRow {
  id: number;
  label: string;
}

interface ArchiveConfirmationProps {
  fieldRow: FieldRow;
  isVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const ArchiveConfirmation = ({
  fieldRow,
  isVisible,
  onClose,
  onCancel,
}: ArchiveConfirmationProps) => {
  const {
    archiveFieldMutations: { mutate: onArchiveField },
  } = useArchivedFieldsMutation();
  const { id, label } = fieldRow;

  const handleArchiveField = (): void => {
    onArchiveField(
      { id },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <ConfirmationModal
      isOpen={isVisible}
      onClose={onClose}
      title={t("profile_fields.archive_confirm.title", {
        fieldLabel: label,
      })}
      onConfirm={handleArchiveField}
      onCancel={onCancel}
      confirmVariant="danger"
      cancelText={t("cancel")}
    >
      <p>{t("profile_fields.archive_confirm.description")}</p>
    </ConfirmationModal>
  );
};
